import {HTTP_INTERCEPTORS, provideHttpClient, withFetch, withInterceptorsFromDi} from '@angular/common/http';
import {APP_INITIALIZER, Injectable, NgModule, PLATFORM_ID, ErrorHandler} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import {MatDialogModule} from '@angular/material/dialog';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatTabsModule} from '@angular/material/tabs';
import {MatBadgeModule} from '@angular/material/badge';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import { BrowserModule, ɵgetDOM, HammerModule, provideClientHydration } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import {AppComponent} from './app.component';
import {AuthInterceptor} from './auth-interceptor';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {SharedModule} from './shared/shared.module';
import {UserDialogComponent} from './user-dialog/user-dialog.component';
import {YesNoDialogComponent} from './yesno-dialog/yesno-dialog.component';
import {NewsletterDialogComponent} from './newsletter-dialog/newsletter-dialog.component';
import {WaitModalComponent} from './wait-modal/wait-modal.component';

import {Angulartics2Module} from 'angulartics2';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {WINDOW} from './shared/services/window.service';
import {MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS} from '@angular/material/progress-spinner';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import {ContactUsDialogComponent} from './contact-us-dialog/contact-us-dialog.component';
import Bugsnag from '@bugsnag/js';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import {environment} from '../environments/environment';
import {LayoutModule} from '@angular/cdk/layout';

@Injectable()
export class FakeProvider {
}

// configure Bugsnag if API key is available (production only)
if (environment.bugSnagApiKey !== null) {
  Bugsnag.start({ apiKey: environment.bugSnagApiKey });
}

// create a factory which will return the Bugsnag error handler
export function errorHandlerFactory() {
  if (environment.bugSnagApiKey !== null) {
    return new BugsnagErrorHandler();
  } else {
    return new ErrorHandler();
  }
}

@NgModule({
    declarations: [
      AppComponent,
      HeaderComponent,
      FooterComponent,
      PageNotFoundComponent,
      UserDialogComponent,
      ContactUsDialogComponent,
      YesNoDialogComponent,
      NewsletterDialogComponent,
      WaitModalComponent,
      UnsubscribeComponent
    ],
    bootstrap: [AppComponent],
    imports: [
      BrowserModule.withServerTransition({ appId: 'mbnmApp' }),
      AppRoutingModule,
      FormsModule,
      MatButtonModule,
      MatListModule,
      MatIconModule,
      MatMenuModule,
      MatDialogModule,
      MatTabsModule,
      MatInputModule,
      MatCheckboxModule,
      MatBadgeModule,
      MatProgressSpinnerModule,
      BrowserAnimationsModule,
      SharedModule.forRoot(),
      Angulartics2Module.forRoot(),
      ReactiveFormsModule,
      LayoutModule,
      HammerModule
    ],
    providers: [
      provideClientHydration(),
      {
        provide: ErrorHandler,
        useFactory: errorHandlerFactory
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
      },
      {
        provide: APP_INITIALIZER,
        useFactory: function (document: HTMLDocument, window: Window, platformId: Object): Function {
          return () => {
            if (isPlatformBrowser(platformId)) {
              const dom = ɵgetDOM();
              const styles: any[] = Array.prototype.slice.apply(dom.getDefaultDocument().querySelectorAll(`style[ng-transition]`));
              styles.forEach(el => {
                  // Remove ng-transition attribute to prevent Angular appInitializerFactory
                  // to remove server styles before preboot complete
                  el.removeAttribute('ng-transition');
              });
            }
          };
        },
        deps: [DOCUMENT, WINDOW, PLATFORM_ID],
        multi: true
      },
      {
        provide: MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS,
        useValue: {
          _forceAnimations: true
        }
      },
      provideHttpClient(withInterceptorsFromDi(), withFetch())
    ]
})
export class AppModule {}
